const OpenInNewIcon = props => (
  <span className="material-icons" style={{ color:'#ffffffbb', fontSize:'16px', margin:'0 5px 0 0', transform:'translateY(4px)' }} >
    open_in_new
  </span>          
)

const Open = props => (
  <>
    { !props.img && 
      <a 
        href={ props.href }
        target="_blank"
        rel="noopener noreferrer"
      >
        <OpenInNewIcon />
      </a>
    }
    <a 
      className={ !props.img && "front-link-hover" }
      style={{ 
        color:'white', 
        fontSize:'12px', 
        lineHeight:'14px', 
        margin:'0 0 4px 0', 
      }} 
      href={ props.href }
      target="_blank"
      rel="noopener noreferrer"
    >
      { props.title && 
        <span>{ props.title }</span>
      }
      { props.img && 
        <img src={ props.img } style={{ maxWidth:200 }} />
      }
    </a>
    <br/>
  </>
)

export default Open
