import React, { useState, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
//import Velocity from 'velocity-animate';
import Store from '../Store/Store';
import Request from '../Request/Request';

import Data from '../Data/Data';
import Synchronize from '../Synchronize/Synchronize';
import BreadCrumbs from '../App/BreadCrumbs';
import Gallery from '../Gallery/Gallery';
import CheckoutWidget from '../Checkout/CheckoutWidget';
import { content } from '../Data/content'
import BookNowLink from '../Activities/BookNowLink';
import ShowMap from '../Activities/ShowMap';
import ActivityPageInfo from '../Activities/ActivityPageInfo';
import ActivityPageItem from '../Activities/ActivityPageItem';

const ActivityPage = props => {

	const { productListId, id } = useParams()
	const [ productLists, setProductLists ] = useState( Data().productLists || [] )
	const [ tmp, setTmp ] = useState( 0 )

	useEffect(() => {

		window.scrollTo( { top: 0, left: 0 } );

		if ( productLists.length === 0 ){
			Data().fetch( ( data ) => {
				setProductLists( data.productLists )
				if ( Store.get( 'activities.' + productListId ) ) return
				Request.get( 'activities/' + productListId, 
					result => { 
						Store.set( 'activities.' + productListId, result.data.items ) 
						setTmp( tmp + 1 )
					}
				) 
			} )
		}

	}, [  ] )

    const domain = Store.get( 'domain' )
    if ( !domain ) return
	const c = content[ domain ]

	let data = Store.get( 'activities.' + productListId )
	let row = null, backTo = 'back', youtubeCode, youtubeCodeStartPos, youtubeCodeEndPos, city

	if ( data && data != null && data.length > 0 ) {
		data.map( activitiesRow => {
			if ( Number( activitiesRow.activity.id ) === Number( id ) ) { 
				row = activitiesRow
				backTo = '/'
				youtubeCode = null
				if ( row.activity.videos.length > 0 ){
    				youtubeCodeStartPos = row.activity.videos[0].sourceUrl.indexOf("v=")
    				if ( youtubeCodeStartPos > 0 ) {
    					youtubeCode = row.activity.videos[0].sourceUrl.substr( youtubeCodeStartPos + 2 )
	    				youtubeCodeEndPos = youtubeCode.indexOf("&")
	    				if ( youtubeCodeEndPos > 0 ) {
	    					youtubeCode = youtubeCode.substr( 0, youtubeCodeEndPos )
	    				} 
					}
				}
				city = ''
				if ( row.activity.hasOwnProperty( 'locationCode' ) && row.activity.locationCode !== null  ) {
					if ( row.activity.locationCode.hasOwnProperty( 'name' ) && row.activity.locationCode.name !== null ) {
						city = row.activity.locationCode.name.toUpperCase()
					}
				}
			}
		} )
		if ( row === null ) {
			row = false
		} 
	}

	if ( !row ) return null

	const keyPhotoRecord = row.activity.keyPhoto && row.activity.keyPhoto.derived ? row.activity.keyPhoto.derived.filter( a => a.name==='large' ) : null
	const keyPhotoUrl = keyPhotoRecord ? keyPhotoRecord[ 0 ].url : ''

	let thumbnailPhotos = []
	row.activity.photos.forEach( a => {  
		if ( !a.derived ) return
		const photoRecord = a.derived.filter( x => x.name==='thumbnail' )
		thumbnailPhotos.push( photoRecord[ 0 ].url )
	} )

	let activityPhotos = []
	row.activity.photos.forEach( a => {  
		if ( !a.derived ) return
		const photoRecord = a.derived.filter( x => x.name==='large' )
		activityPhotos.push( photoRecord[ 0 ].url )
	} )

	//console.log( row.activity )

	return ( 
    	<div style={{ textAlign:'left' }} >

    		<BreadCrumbs 
    			to={ backTo }
    			img={ keyPhotoUrl }
    			titles={ [ row.activity.title ] } 
			/>

	        <div className="products" >

        	<div className="products-flex-box" >
    			<div style={{ padding:'0 30px 10px 20px' }} >
    			
			    	<h1 style={{ lineHeight:'34px', marginTop:'30px' }} >{ row.activity.title }</h1>

			    	<ActivityPageInfo 
			    		city={ city } 
			    		difficultyLevel={ row.activity.difficultyLevel }
			    		durationText={ row.activity.durationText }
		    		/>

			    	<BookNowLink/>

			    	<div style={{ marginTop:'20px', fontWeight:500 }} >{ row.activity.excerpt }</div>

		    		<Gallery thumbnails={ thumbnailPhotos } images={ activityPhotos } /> 


			    	<ActivityPageItem title='Description' content={ row.activity.description } />
					<ActivityPageItem title='Price' content={ 'Price from ' + row.activity.nextDefaultPrice + ' ' + c.currencySymbol } />

			    	{ row.activity.googlePlace && row.activity.googlePlace.geoLocationCenter.lng !==0 && <ShowMap
			    		lng={ row.activity.googlePlace.geoLocationCenter.lng }
			    		lat={ row.activity.googlePlace.geoLocationCenter.lat }
			    	/> }

			    	<ActivityPageItem title='What is included?' content={ row.activity.included } />
			    	<ActivityPageItem title='Please note' content={ row.activity.attention } />
			    	<ActivityPageItem title='Ticketing' content={ row.activity.ticketMsg } />
			    	<ActivityPageItem title='Duration' content={ row.activity.durationText } />
			    	<ActivityPageItem title='Minimum age' content={ row.activity.minAge } />

			    	{ row.activity.startPoints.length > 0 && 
		    		  <> 
				    	<ActivityPageItem title='Start Point' lines={ [
					    	row.activity.startPoints[ 0 ].address.addressLine1, 
					    	row.activity.startPoints[ 0 ].address.addressLine2, 
					    	row.activity.startPoints[ 0 ].address.addressLine3, 
					    	row.activity.startPoints[ 0 ].address.city, 
					    	row.activity.startPoints[ 0 ].address.postalcode, 
				    	] } />
			    		<ShowMap 
			    			lat={ row.activity.startPoints[0].address.geoPoint.latitude } 
			    			lng={ row.activity.startPoints[0].address.geoPoint.longitude } 
			    			zoom={ row.activity.startPoints[0].address.mapZoomLevel } 
			    		/>
			    	  </> 
			    	}

				    { youtubeCode && 
			    	  <>
				    	<h3>Video</h3> 
				    	<div
					      style={{
					        position:"relative",
					        paddingBottom:"56.25%",
					        paddingTop:25,
					        height:0
					      }}
					    >
					      <iframe
					        style={{
					          position:"absolute",
					          top:0,
					          left:0,
					          width: "100%",
					          height: "100%"
					        }}
					        title="video"
					        src={`https://www.youtube.com/embed/${youtubeCode}`}
					        frameBorder="0"
					      />
					    </div>
				      </> 
					}

			    	<ActivityPageItem title='Cancellation Policy' content={ row.activity.cancellationPolicy.title } />
			    	<ActivityPageItem title='Vendor' content={ row.activity.vendor.title } />

		    	</div> 
		    	<CheckoutWidget activityId={ id } />
	    	</div>
	    	</div>
		    	
	    </div>
	)
	
}


export default ActivityPage

