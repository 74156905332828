import React, { useState, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import parse from 'html-react-parser';
import Data from '../Data/Data';
import BreadCrumbs from '../App/BreadCrumbs';
import ActivitiesList from '../Activities/ActivitiesList';

const BlogPage = props => {

	const { slug } = useParams()
	const [ blogListIndex, setBlogListIndex ] = useState( 0 )
	const [ blogs, setBlogs ] = useState( Data().blogs || [] )
	const [ tmp, setTmp ] = useState( 0 )

	const assetsRoot = window.document.domain === 'localhost' ? 'https://stopover.qa/assets/' : 'https://' + window.document.domain +'/assets/'

	useEffect(() => {

		window.scrollTo( { top: 0, left: 0 } );

		Data().fetch( ( data ) => {
			setBlogs( data.blogs )
			data.blogs.forEach( ( a, i ) => {
				if ( a.slug === slug ) setBlogListIndex( i )
			} )
		} )
		blogs.forEach( ( a, i ) => {
			if ( a.slug === slug ) setBlogListIndex( i )
		} )

	}, [  ] )


	let backTo = '/back/'
	//let backTo = '/activities/'

	if ( blogs.length === 0 ) return null

	return ( 
		<Fragment>

    		<BreadCrumbs 
    			to={ backTo }
    			//history={ this.props.history }
    			img={ assetsRoot + blogs[ blogListIndex ].img } 
    			titles={ [ 'Blogs', blogs[ blogListIndex ].title ] } 
			/>

	        <div className="products" >
    			<div style={{ padding:'0 30px 10px 20px' }} >
			    	<h1 style={{ lineHeight:'50px', margin:'20px 0 0 0', textAlign:'left' }} >{ blogs[ blogListIndex ].title }</h1>
		    	</div>
    			<p style={{ textAlign:'left', padding:'0 30px 10px 20px' }} >
	 		    	{ blogs[ blogListIndex ].display_date || '' }
		    	</p>
    			<div style={{ textAlign:'left', padding:'0 30px 10px 20px' }} >
	 		    	{ parse( blogs[ blogListIndex ].copy || '' ) }
		    	</div>
	    	</div>

	    	{ blogs[ blogListIndex ].productListId && 
	    	  <>
		        <div className="products" >
	    			<div style={{ padding:'0 30px 10px 20px' }} >
				    	<h1 style={{ lineHeight:'50px', margin:'20px 0 0 0', textAlign:'left' }} >Related Activities</h1>
			    	</div>
		    	</div>
				<ActivitiesList
					limit="12"
					productListId={ blogs[ blogListIndex ].productListId }
				/>
			  </>
			}

		</Fragment>
	)

}

export default BlogPage

